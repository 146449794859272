import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from "react";

import "./styles.css";

import { cn } from "~/utilities/cn";
import { removeNbspParagraphs } from "~/utilities/helpers/removeNbspParagraphs";

interface Props {
  htmlContent: string;
  className?: string;
}

const CleanedContent: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { htmlContent, className },
  ref
) => {
  const [cleanedContent, setCleanedContent] = useState("");

  useEffect(() => {
    // Clean the content only on the client side after the component has mounted
    const cleaned = removeNbspParagraphs(htmlContent);
    setCleanedContent(cleaned);
  }, [htmlContent]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: cleanedContent }}
      className={cn("ql-editor", className)}
      ref={ref}
    />
  );
};

const CleanedContentWithRef = forwardRef(CleanedContent);

export default CleanedContentWithRef;
